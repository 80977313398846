import Vue from 'vue'
import Keycloak from 'keycloak-js'
import AppConfig from '../../config.js';

const options = {
  url: AppConfig.KC_Base_URL + '/auth/',
  realm: 'rasreeRealm',
  clientId: 'adminAppClient'
}

const _keycloak = Keycloak(options)

const Plugin = {
  install(Vue) {
    Vue.$keycloak = _keycloak
  }
}

Plugin.install = Vue => {
  Vue.$keycloak = _keycloak
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin