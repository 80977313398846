import Vue from 'vue'
import VueRouter from 'vue-router'
//import keycloak from '../KC_auth';
import AppConfig from '../../config.js';
import axios from 'axios';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../components/Home.vue'),
    meta:{
      isAuthNeeded: false
    }
  },
  {
    path: '/tutor',
    name: 'workbook_home_tutor',
    component: () => import('../components/workbook_home_tutor.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/tutor/:wid',
    name: 'workbook_live_tutor',
    component: () => import('../components/workbook_live_tutor.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/protege',
    name: 'workbook_home_protege',
    component: () => import('../components/workbook_home_protege.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/protege/wait',
    name: 'workbook_wait_protege',
    component: () => import('../components/workbook_wait_protege.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/protege/:wid',
    name: 'workbook_live_protege',
    component: () => import('../components/workbook_live_protege.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/workbook/:wid',
    name: 'workbook_id',
    component: () => import('../components/workbook_id.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/workstation/:wid',
    name: 'workstation_id',
    component: () => import('../components/workstation_id.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/login',
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/logout',
    meta:{
      isAuthNeeded: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: 'is-active',
})

router.beforeEach((to, from, next) => {
    if (to.meta.isAuthNeeded) {
      // Get the actual url of the app, it's needed for Keycloak
      const basePath = AppConfig.UI_Base_URL+"/"
      console.log("base url", basePath)
      if (!Vue.$keycloak.authenticated) {
        // The page is protected and the user is not authenticated. Force a login.
        let redirect_to = basePath.slice(0, -1) + to.path
        if(redirect_to.includes("login")){
          redirect_to = basePath
        }
        
        Vue.$keycloak.login({ redirectUri: redirect_to })
      } else{
        // The user was authenticated, and has the app role
        setInterval(() => {
            Vue.$keycloak.updateToken(70).then((refreshed) => {
              if (refreshed) {
                localStorage.setItem('access-token', Vue.$keycloak.token);
                localStorage.setItem('refresh-token', Vue.$keycloak.refreshToken);
                localStorage.setItem('id-token', Vue.$keycloak.idToken);
                axios.defaults.headers.common['Authorization'] = "Bearer " + Vue.$keycloak.token
                axios.defaults.headers.common['id-token'] = "Bearer " + Vue.$keycloak.idToken
                axios.defaults.headers.common['access-token'] = "Bearer " + Vue.$keycloak.token
                axios.defaults.headers.common['refresh-token'] = "Bearer " + Vue.$keycloak.refreshToken
                console.log('Token refreshed' + refreshed);
              } else {
                console.log('Token not refreshed, valid for '
                  + Math.round(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
              }
            }).catch(() => {
              console.log('Failed to refresh token');
            });
          }, 6000)
        next();
      }
        localStorage.setItem('access-token', Vue.$keycloak.token);
        localStorage.setItem('refresh-token', Vue.$keycloak.refreshToken);
        localStorage.setItem('id-token', Vue.$keycloak.idToken);
        axios.defaults.headers.common['Authorization'] = "Bearer " + Vue.$keycloak.token
        axios.defaults.headers.common['id-token'] = "Bearer " + Vue.$keycloak.idToken
        axios.defaults.headers.common['access-token'] = "Bearer " + Vue.$keycloak.token
        axios.defaults.headers.common['refresh-token'] = "Bearer " + Vue.$keycloak.refreshToken
    } else {
      // This page did not require authentication
      if(Vue.$keycloak.authenticated){
        localStorage.setItem('access-token', Vue.$keycloak.token);
        localStorage.setItem('refresh-token', Vue.$keycloak.refreshToken);
        localStorage.setItem('id-token', Vue.$keycloak.idToken);
        axios.defaults.headers.common['Authorization'] = "Bearer " + Vue.$keycloak.token
        axios.defaults.headers.common['id-token'] = "Bearer " + Vue.$keycloak.idToken
        axios.defaults.headers.common['access-token'] = "Bearer " + Vue.$keycloak.token
        axios.defaults.headers.common['refresh-token'] = "Bearer " + Vue.$keycloak.refreshToken
      }
      next()
    }
  })


export default router
