<template>
  <v-app>
    <!--<v-main>
      <v-app-bar color="rgb(43, 3, 90)" app clipped-left clipped-right flat class="topnav">
        <router-link to="/">Home</router-link> &nbsp;&nbsp;
        <router-link to="/workbook">Workbook</router-link>
      </v-app-bar>
      <hr>
      <router-view></router-view>
    </v-main>-->
    <div v-show="isAuth" class="topnav">
      <a :href="'/'">Home</a>
      <div style="float:right;">
         <h2 style="cursor: pointer;color:red">{{ UserName }}</h2>
      </div>
    </div>
  <router-view/>
  </v-app>
</template>

<script>
import jwt_decode from 'jwt-decode';
import AppConfig from '../config.js';
import axios from 'axios';
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    isAuth: false,//TODO
    UI_Base_URL: AppConfig.UI_Base_URL,
    auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
    get_acc_token: "",
    get_id_token: "",
    get_refresh_token: "",
    UserName:"",
    //
  }),
  beforeMount(){
    console.log("is auth done",this.$keycloak);
    //this.username = this.$keycloak.tokenParsed.email
    //console.log("username:",tokenParsed.email);
    /*if(this.isAuth==true){
      window.location = AppConfig.UI_Base_URL + ''
    }*/
    if(localStorage.getItem('access-token') != null){
        this.get_acc_token = localStorage.getItem('access-token')
        this.get_id_token = localStorage.getItem('id-token')
        this.get_refresh_token = localStorage.getItem('refresh-token')
        let tokenParsed = jwt_decode(this.get_acc_token);
        console.log("tokenparsed",tokenParsed);
        this.UserName = tokenParsed["email"]
    }
    let request_url_userme = this.auth_api_Base_URL + '/v1/me'
    axios.get(request_url_userme,{
      headers:{
          Authorization: 'Bearer ' + this.get_acc_token,
          "id-token": this.get_id_token,
          "access-token": this.get_acc_token,
          "refresh-token": this.get_refresh_token,
      }
    }).then(response => {
      console.log("userme", response);
      if(response.headers["status_code"] === "200"){
          this.isAuth = true
      }
      else{
          this.isAuth = true//'TODO'
      }
    });

  }
};
</script>
<style lang="scss">
@import './main.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.is-active.router-link-active {
    border: 1px solid #fff;
    color: rgb(233, 23, 23) !important;
  }
</style>
